<template>
    <div class="main">
        <div class="row">
            <div class="col-md-12" style="position: relative">
                <MainImage />
                <div class="video">
                    <!-- <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/7hHuKORIruE"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe> -->
                    <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/sCX5AiG37Cw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                    <iframe width="560" height="315" src="https://www.youtube.com/embed/UhQzWKrr2QY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>
                <div class="mainstage-center">
                    <a href="https://store.steampowered.com/app/1521410/Manic_Archers/" target="_blank">
                        <div class="btn btn-primary" >
                            BUY ON STEAM NOW!
                        </div>
                    </a>
                </div>
            </div>
        </div>
        <div class="socialbar row">
            <div class="row">
                <div class="offset-md-2 col-md-8 d-flex justify-content-end">
                    <div>
                        <a href="https://www.facebook.com/manicarchers" target="_blank">
                            <img src="../assets/social/facebook.png" />
                        </a>
                    </div>
                    <div>
                        <a href="https://twitter.com/manicarchers" target="_blank">
                            <img src="../assets/social/twitter.png" />
                        </a>
                    </div>
                    <div>
                        <a href="https://www.youtube.com/channel/UCVRVHI21atpXh9hXSWtzF1w" target="_blank">
                            <img src="../assets/social/youtube.png" />
                        </a>
                    </div>
                    <div class="fb-like" data-href="https://www.facebook.com/manicarchers" data-width="" data-layout="button_count" data-action="like" data-size="small" data-share="true"></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mt-3">
                <router-link to="/">
                    <div class="btn btn-primary" style="margin-right: 10px;">
                        HOME
                    </div>
                </router-link>
                <a href="/manic_archers_presskit.zip">
                    <div class="btn btn-primary" >
                        PRESS KIT
                    </div>
                </a>
            </div>
        </div>
        <div class="content">
            <div class="row">
                <div class="offset-md-2 col-md-8">
                    <h1>MANIC ARCHERS</h1>
                    <h4 class="small" style="margin-top: -15px; background: #ff4e2e; color: #fff; width: fit-content; border-radius: 4px; padding: 2px 5px;">early access</h4>
                    <h2>MAP PIXELART CONTEST</h2>
                </div>
            </div>
            <div class="row">
                <div class="offset-md-2 col-md-8 mt-4">
                    <h4 class="small">OPEN CONTEST FOR PIXELART MAPS</h4>
                    Manic Archers needs more quality maps and more varying styles by different artists. We therefore decided to support the pixelart-community with a nice open contest.<br>
                    Each submitted map, which will be used in the game, will be rewarded with <span style="font-size: 25px; font-weight: 700">$ 400</span> (paid out via PayPal with fees on my side).<br>
                    <br>
                    This contest is ongoing until Manic Archers has enough new maps (for the beginning this will be at least two maps to be rewarded).<br>
                    <br>
                    Winners will be announced here and on <a href="https://twitter.com/manicarchers" target="_blank">Twitter</a>, so if you want to know if the <b>contest is still active or be notified about the winners</b>, follow <a href="https://twitter.com/manicarchers" target="_blank">Manic Archers on Twitter</a> or check back on this site.<br>
                    <br>
                    There is no specific deadline and the contest will be held until at least two map-submissions are good enough, to be included into the game.<br>
                    <br>
                </div>
                <div class="offset-md-2 col-md-8 mt-4">
                    <h4 class="small">DETAILS</h4>
                    The maps have to be a PNG or PSD of size 960x644 pixels with a 16x16 grid (you can of course neglect the grid and paint freehand as well). <br>
                    <br>
                    The files have to be delivered per e-mail to <a href="mailto:contest@manicarchers.com">contest@manicarchers.com</a>.<br>
                    <br>
                    There is no specific deadline, though if high quality submissions come in shortly after the contest is officially over (as announced on this site then), if convincingly good enough, they might still be rewarded and added to the game.<br>
                    <br>
                    Winners will be paid per PayPal shortly after being announced on this site / via Twitter - each will also receive a Steam Key to download Manic Archers for free.<br>
                    <br>
                    Quality of the maps should be at least similar to the maps included in the game so far, so you might want to have a look at the <a href="https://store.steampowered.com/app/1521410/Manic_Archers/" target="_blank">screenshots on Steam</a> for reference.<br>
                    <br>
                    The setting of Manic Archers is usually medieval/fantasy, so an urban or cyberpunk map will probably have bad chances - though we are of course interested in creative ideas.<br>
                    The painting style is basically that of classic top-down RPGs like the 2D Final Fantasy- or Zelda-series.
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mt-5">
                <a href="https://store.steampowered.com/app/1521410/Manic_Archers/" target="_blank">
                    <div class="btn btn-primary ms-3 me-3" >
                        VISIT MANIC ARCHERS PAGE ON STEAM
                    </div>
                </a>
            </div>
        </div>
        <div class="footer mt-3 mb-3">
            <div class="row">
                <div class="col-md-12 text-center">
                    Copyright 2020-{{new Date().getFullYear()}}
                    <a href="https://ancientmosthigh.com/" target="_blank">Ancient Most High Games</a>
                    <br>
                    <router-link to="/privacy">
                        Privacy Policy<br>
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    // @ is an alias to /src
    import MainImage from '@/components/MainImage.vue'

    export default {
        data() {
            return {
                screen1: require('../assets/screenshots/3.png'),
                screen2: require('../assets/screenshots/1.png'),
                screen3: require('../assets/screenshots/5.png'),
                screen4: require('../assets/screenshots/8.png')
            }
        },
        name: 'Home',
        components: {
            MainImage
        }
    }
</script>