<template>
    <div class="mainImage">
        <img class="mainimg-placeholder" src="../assets/background/full.png" />
        <!-- <img class="overlay-image mainimg-bg" src="../assets/background/full.png"> -->
        <!-- <div class="overlay-image-scroll">
            <div id="clouds-row" class="scroll">
                <div class="container-row">
                    <img id="clouds1" class="mainimg-distance-clouds" src="../assets/background/clouds.png">
                    <img id="clouds2" class="mainimg-distance-clouds" src="../assets/background/clouds.png">
                </div>
            </div>
        </div>
        <img class="overlay-image mainimg-distance" src="../assets/background/hills3.png">
        <img class="overlay-image mainimg-distance" src="../assets/background/hills2.png">
        <img class="overlay-image mainimg-distance" src="../assets/background/hills1.png">
        <img class="overlay-image mainimg-distance" src="../assets/background/foreground.png"> -->
        <img class="overlay-image logo" src="../assets/logo1.png" />
        <a href="https://ancientmosthigh.com/" target="_blank">
            <img
                class="overlay-image company"
                title="Ancient Most High Games"
                src="../assets/logopixelwhite.png"
            />
        </a>
    </div>
</template>

<script>
export default {
    data() {
        return {};
    },
    methods: {
        moveClouds() {
            // var clouds = document.getElementById("clouds-row")
            // let clouds1 = document.getElementById("clouds1")
            // let cloudswidth = clouds1.clientWidth
            // // console.log('CLIENT WIDTH'+cloudswidth)
            // let increment = 2;
            // var leftval = Number(window.getComputedStyle(clouds,null).getPropertyValue("left").replace("px", ""));
            // leftval -= increment;
            // // console.log("test:" +leftval+ ", other: "+ cloudswidth + "result: "+(cloudswidth+leftval))
            // if (cloudswidth+leftval <= 0) {
            //     leftval = 0;
            // }
            // clouds.style.left = leftval.toString() + "px";
            // window.setTimeout(this.moveClouds, 50);
        },
    },
    mounted: function () {
        this.moveClouds();
    },
};
</script>
