<template>
    <div class="main">
        <div class="row">
            <div class="col-md-12" style="position: relative">
                <MainImage />
                <div class="video">
                    <!-- <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/7hHuKORIruE"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe> -->
                    <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/sCX5AiG37Cw" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                    <!-- <iframe width="560" height="315" src="https://www.youtube.com/embed/UhQzWKrr2QY title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe> -->
                    <iframe
                        width="560"
                        height="315"
                        src="https://www.youtube.com/embed/t54IP24YD48"
                        title="YouTube video player"
                        frameborder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen
                    ></iframe>
                </div>
                <div class="mainstage-center">
                    <a
                        href="https://store.steampowered.com/app/1521410/Manic_Archers/"
                        target="_blank"
                    >
                        <div class="btn btn-primary" style="margin-right: 15px">
                            BUY ON STEAM!
                        </div>
                    </a>
                    <a
                        href="https://ancientmosthigh.itch.io/manicarchers"
                        target="_blank"
                    >
                        <div class="btn btn-primary">BUY ON ITCH.IO!</div>
                    </a>
                </div>
            </div>
        </div>
        <div class="socialbar row">
            <div class="row">
                <div class="offset-md-2 col-md-8 d-flex justify-content-end">
                    <div>
                        <a
                            href="https://www.facebook.com/manicarchers"
                            target="_blank"
                        >
                            <img src="../assets/social/facebook.png" />
                        </a>
                    </div>
                    <div>
                        <a
                            href="https://twitter.com/manicarchers"
                            target="_blank"
                        >
                            <img src="../assets/social/twitter.png" />
                        </a>
                    </div>
                    <div>
                        <a
                            href="https://www.youtube.com/channel/UCVRVHI21atpXh9hXSWtzF1w"
                            target="_blank"
                        >
                            <img src="../assets/social/youtube.png" />
                        </a>
                    </div>
                    <div
                        class="fb-like"
                        data-href="https://www.facebook.com/manicarchers"
                        data-width=""
                        data-layout="button_count"
                        data-action="like"
                        data-size="small"
                        data-share="true"
                    ></div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mt-3">
                <a href="/manic_archers_presskit.zip">
                    <div
                        class="btn btn-primary mb-2"
                        style="margin-right: 10px"
                    >
                        PRESS KIT
                    </div>
                </a>
                <a
                    href="https://store.steampowered.com/app/2339820/Manic_Archers__Bullseye_DEMO/"
                    target="_blank"
                >
                    <div class="btn btn-primary mb-2">DOWNLOAD FREE DEMO</div>
                </a>
            </div>
        </div>
        <div class="content">
            <div class="row">
                <div class="offset-md-2 col-md-8">
                    <h1>MANIC ARCHERS</h1>
                    <h4
                        class="small"
                        style="
                            margin-top: -4px;
                            background: #ff4e2e;
                            color: #fff;
                            width: fit-content;
                            border-radius: 4px;
                            padding: 2px 5px;
                            padding-top: 3px;
                        "
                    >
                        early access
                    </h4>
                    <h2>ULTIMATE local multiplayer retrogaming arcade</h2>
                </div>
            </div>
            <div class="row">
                <div class="offset-md-2 col-md-8 mt-4">
                    <a :href="screen1" target="_blank">
                        <img
                            src="../assets/screenshots/1.jpg"
                            style="float: right; margin-top: 0px"
                        />
                    </a>
                    <h4 class="small">GAMEPLAY FIRST</h4>
                    MANIC ARCHERS is an atmospheric and extremely
                    <span class="em"
                        >addictive local and online multiplayer arcade</span
                    >
                    game in Pixel Art-style.<br />
                    It features simplistic and
                    <span class="em">on-the-point gameplay</span> with
                    <span class="em">amazing game mechanics</span> and lovely
                    Pixel Art-details.<br />
                    It is playable with up to
                    <span class="em">4 players simultaneously</span> in several
                    variants.<br />
                    <br />
                    <span class="em">3 buttons</span> is all you need to know -
                    <span class="em">jump, shoot and dash.</span> The depth of
                    good gameplay is found in
                    <span class="em"
                        >simplicity accompanied by great mechanics</span
                    >.
                </div>
            </div>
            <div class="row">
                <div class="offset-md-2 col-md-8 mt-3">
                    <a :href="screen2" target="_blank">
                        <img
                            src="../assets/screenshots/2.jpg"
                            style="
                                float: left;
                                margin-top: 0px;
                                margin-left: 0px;
                            "
                        />
                    </a>
                    <h4 class="small">Think Zelda and deathmatch</h4>
                    With the intention to make a multiplayer match top-down
                    game, MANIC ARCHERS owes to and actually
                    <span class="em"
                        >should remind of the heritage of all top-down</span
                    >
                    games like the Zelda and Final Fantasy series. Imagine the
                    same <span class="em">love for details</span> put into a
                    competitive multiplayer arcade game for 4 players
                    simultaneously.<br />
                </div>
            </div>
            <div class="row">
                <div class="offset-md-2 col-md-8 mt-3">
                    <a :href="screen3" target="_blank">
                        <img
                            src="../assets/screenshots/5.png"
                            style="float: right; margin-top: 0px"
                        />
                    </a>
                    <h4 class="small">Approved multiplayer fun</h4>
                    In versus-mode players each control an archer, shooting the
                    opposing archers until only one archer is left - besides
                    Free-For-All versus, Manic Archers is featuring several
                    other
                    <span class="em">well known multiplayer modes</span>.<br />
                    During the game a lot of
                    <span class="em">game changing powerups</span> and special
                    arrows appear on the playing field, enabling players to
                    <span class="em">turn the game around</span> in an
                    instant.<br />
                </div>
            </div>
            <div class="row">
                <div class="offset-md-2 col-md-8 mt-3">
                    <a :href="screen4" target="_blank">
                        <img
                            src="../assets/screenshots/3.jpg"
                            style="float: left; margin-top: 0px; margin-left: 0"
                        />
                    </a>
                    <h4 class="small">
                        retro cause we do not need 3d for that
                    </h4>
                    The game takes place on a
                    <span class="em"
                        >single screen with classic arcade wrap-around
                        scrolling</span
                    >. The players
                    <span class="em"
                        >make use of the top-down environment
                        strategically</span
                    >
                    such as water, higher grounds, obstacles and
                    <span class="em">interactive map parts</span> such as
                    switches, explosives, burnable trees and more.<br />
                </div>
            </div>
            <div class="row">
                <div class="col-md-12 text-center mt-3">
                    <a
                        href="https://store.steampowered.com/app/1521410/Manic_Archers/"
                        target="_blank"
                    >
                        <div class="btn btn-primary me-3">
                            VISIT MANIC ARCHERS PAGE ON STEAM
                        </div>
                    </a>
                    <a
                        href="https://ancientmosthigh.itch.io/manicarchers"
                        target="_blank"
                    >
                        <div class="btn btn-primary">
                            VISIT MANIC ARCHERS PAGE ON ITCH.IO!
                        </div>
                    </a>
                </div>
            </div>
            <div class="row">
                <div class="offset-md-2 col-md-8 mt-2">
                    <h3>Key Features</h3>
                    <h4>Up to 4 players online and local</h4>
                    Play
                    <span class="em">arcade style locally AND online</span> with
                    up to 4 Players (with Remote Play Together)<br />
                    <h4>Versus</h4>
                    <span class="em">Simple Free-For-All match, fast paced</span
                    >, deep gameplay mechanics (also
                    <span class="em">available for Teamplay</span>)<br />
                    <h4>King of the hill</h4>
                    <span class="em">Keep control</span> of the center of the
                    map to gain points and secure the round (also available for
                    Teamplay)<br />
                    <h4>Catch the fool with the chicken</h4>
                    All players match against the one who got the chicken - the
                    one
                    <span class="em">who keeps the chicken longest wins</span>
                    the round<br />
                    <h4>Conquer</h4>
                    Dominate parts of the map to gain points, the one
                    <span class="em"
                        >who collected and held most flags the longest
                        wins</span
                    >
                    <h4>Survival Coop and Singleplayer</h4>
                    How long can you and your friends
                    <span class="em">survive against hordes of enemies</span
                    >?<br />
                    <h4>Deep gameplay mechanics</h4>
                    The gameplay is set up for
                    <span class="em">competitive and fun matches</span> through
                    <span class="em">deep but easy to learn</span> game
                    mechanics<br />
                    <h4>Interactive and individual maps</h4>
                    <span class="em">Interactive maps</span> allow strategic
                    advances which are easy to understand and use. The
                    interactive elements available
                    <span class="em">vary in each map</span><br />
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-12 text-center mt-5">
                <a
                    href="https://store.steampowered.com/app/1521410/Manic_Archers/"
                    target="_blank"
                >
                    <div class="btn btn-primary ms-3 me-3">
                        VISIT MANIC ARCHERS PAGE ON STEAM
                    </div>
                </a>
                <a
                    href="https://ancientmosthigh.itch.io/manicarchers"
                    target="_blank"
                >
                    <div class="btn btn-primary">
                        VISIT MANIC ARCHERS PAGE ON ITCH.IO!
                    </div>
                </a>
            </div>
        </div>
        <div class="footer mt-3 mb-3">
            <div class="row">
                <div class="col-md-12 text-center">
                    Copyright 2020-{{ new Date().getFullYear() }}
                    <a href="https://ancientmosthigh.com/" target="_blank"
                        >Ancient Most High Games</a
                    >
                    <br />
                    <router-link to="/privacy">
                        Privacy Policy<br />
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import MainImage from "@/components/MainImage.vue";

export default {
    data() {
        return {
            screen1: require("../assets/screenshots/3.png"),
            screen2: require("../assets/screenshots/1.png"),
            screen3: require("../assets/screenshots/5.png"),
            screen4: require("../assets/screenshots/8.png"),
        };
    },
    name: "Home",
    components: {
        MainImage,
    },
};
</script>