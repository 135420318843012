<template>
    <div class="p-3">
        <div>
            <div class="row mt-2">
                <div class="col-md-2 d-flex justify-content-end">
                    <label style="color: #fff"> Prompt: </label>
                </div>
                <div class="col-md-10">
                    <textarea
                        v-model="inputPrompt"
                        maxlength="4000"
                        style="
                            width: 700px;
                            height: 180px;
                            font-family: verdana;
                        "
                    >
                    </textarea>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-2 d-flex justify-content-end">
                    <label style="color: #fff">Image-size: </label>
                </div>
                <div class="col-md-10">
                    <select v-model="size" style="font-family: verdana">
                        <option value="1024x1024">1024x1024</option>
                        <option value="1792x1024">1792x1024</option>
                        <option value="1024x1792">1024x1792</option>
                    </select>
                </div>
            </div>
            <div class="row mt-2">
                <div class="col-md-2 d-flex justify-content-end">
                    <label style="color: #fff">HD quality: </label>
                </div>
                <div class="col-md-10">
                    <input type="checkbox" v-model="hd" />
                </div>
            </div>
            <div class="row mt-3">
                <div class="col-md-2 d-flex justify-content-end"></div>
                <div class="col-md-3">
                    <div @click="getImage()" class="btn btn-primary">
                        GENERATE IMAGE
                    </div>
                </div>
                <div v-if="loading" class="col-md-3">
                    <div class="lds-spinner">
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                </div>
            </div>
            <div v-if="data" class="row mt-5">
                <div v-for="(d, i) in data" class="col-md-12">
                    <img
                        style="height: 600px"
                        :src="'data:image/png;base64, ' + d.b64_json"
                    />
                </div>
            </div>
            <div v-else-if="error" class="mt-5" style="color: #fff">
                ERROR: <br />
                {{ error }}
            </div>
            <div v-if="recentPrompts" class="row mt-5">
                <h4 style="color: #fff">RECENT PROMPTS</h4>
                <div
                    @click="loadRecentPrompt(r)"
                    v-for="(r, i) in recentPrompts"
                    class="col-md-12"
                    style="cursor: pointer; color: #fff"
                >
                    {{ i + 1 }}. {{ r }}
                </div>
            </div>
        </div>

        <div class="footer mt-5 mb-3">
            <div class="row">
                <div class="col-md-12 text-center">
                    Copyright 2020-{{ new Date().getFullYear() }}
                    <a href="https://ancientmosthigh.com/" target="_blank"
                        >Ancient Most High Games</a
                    >
                    <br />
                    <router-link to="/privacy">
                        Privacy Policy<br />
                    </router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
// @ is an alias to /src
import MainImage from "@/components/MainImage.vue";
import api from "@/env.js";
// import OpenAI from "openai";

export default {
    data() {
        return {
            screen1: require("../assets/screenshots/3.png"),
            screen2: require("../assets/screenshots/1.png"),
            screen3: require("../assets/screenshots/5.png"),
            screen4: require("../assets/screenshots/8.png"),

            loading: false,
            data: null,
            error: null,

            inputPrompt: "",
            size: "1024x1024",
            hd: false,

            recentPrompts: null,
        };
    },
    name: "Home",
    components: {
        MainImage,
    },
    methods: {
        loadRecentPrompt(p) {
            this.inputPrompt = p;
        },
        getImage() {
            if (!this.inputPrompt.trim()) {
                alert("Please enter prompt.");
            }
            console.log("loading...");
            this.data = null;
            this.error = null;

            let addHD = "";
            if (this.hd) {
                addHD = ',\n"quality":"hd"\n';
            }

            localStorage.setItem("prompt", this.inputPrompt);
            localStorage.setItem("size", this.size);
            if (!this.recentPrompts) {
                this.recentPrompts = [];
            }
            if (!this.recentPrompts.includes(this.inputPrompt)) {
                this.recentPrompts.push(this.inputPrompt);
            }
            localStorage.setItem(
                "recentPrompts",
                JSON.stringify(this.recentPrompts)
            );

            let context = this;
            context.loading = true;
            let call = fetch("https://api.openai.com/v1/images/generations", {
                body:
                    '{\n"model": "dall-e-3",\n"prompt": "' +
                    context.inputPrompt.replaceAll('"', '\\"') +
                    '",\n"n": 1,\n"size": "' +
                    context.size +
                    '",\n"response_format":"b64_json"' +
                    addHD +
                    "\n}",
                headers: {
                    Authorization: "Bearer " + api,
                    "Content-Type": "application/json",
                },
                method: "POST",
            });
            call.then((response) => {
                console.log("fetch result:");
                console.log(response);
                let json = response.json();
                json.then((r) => {
                    console.log("fetch result 2:");
                    console.log(r);
                    if (r.data) {
                        context.data = r.data;
                    } else if (r.error) {
                        context.error = r.error.message;
                    }
                    context.loading = false;
                });
                // console.log(json.results);
            }).catch((error) => {
                console.log("ERROR");
                console.log(error);
                context.error = error;
                context.loading = false;
            });

            //             let call = fetch("https://api.placekit.co/search", {
            //     body:
            //         '{"query": "' +
            //         this.strasse +
            //         '", "countries": ["de"], "maxResults": 20}',
            //     headers: {
            //         "Content-Type": "application/json",
            //         "X-Placekit-Api-Key":
            //             "pk_YyK9tvRf0Q+iCmnu9VKJznRYE4KB9eHpN+1ZyBPfdFk=",
            //     },
            //     method: "POST",
            // });
            // call.then((response) => {
            //     let json = response.json();
            //     json.then((r) => {
            //         context.suggestions = [];
            //         context.addressResults = r.results;
            //         context.prepareAddressResults();
            //     });
            //     // console.log(json.results);
            // }).catch((error) => {
            //     console.log("ERROR");
            //     console.log(error);
            // });
        },
    },
    mounted: function () {
        console.log(api);

        let p = localStorage.getItem("prompt");
        if (p) {
            this.inputPrompt = p;
        }
        let s = localStorage.getItem("size");
        if (s) {
            this.size = s;
        }
        let rps = localStorage.getItem("recentPrompts");
        if (rps) {
            this.recentPrompts = JSON.parse(rps);
        }

        // this.getImage();
    },
};
</script>